.sub-price-table {

    display: flex;
    // flex-direction: column;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    &__item {
        width: 350px;
        background: #fcfff6;
        box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.1), 0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07);
        border: none;
        border-radius: 7px;
        padding: 20px;

        &__header {
            // display: flex;
            // justify-content: space-between;

            &__actions {
                display: flex;
                gap: 10px;
                margin-top: 10px;

                div {
                    min-width: auto;
                    min-height: auto;
                    height: 24px;
                    cursor: pointer;
                }
            }
        }
    }

    table {
        border-spacing: 0 5px !important;
    }
}