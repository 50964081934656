.sublocation-item {
    height: 28px;
    padding: 8px !important;
    color: #091b3d !important;
    background: #f1f4fc !important;
}

.add-sublocation-button {
    height: 28px;
}

.delete-sublocation-icon {
    height: 16px;
}