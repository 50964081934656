@import 'src/style/mixin.scss';

.tag-modal {
  width: 1000px;

  &__container {
    @include flex(flex-start, 5, row, flex-start);

    &__tags-remove {
      width: 50%;
      min-height: 60px;
      @include flex(flex-start, 5, row, flex-start);
      flex-wrap: wrap;
      border: solid 1px #ccc;
      border-radius: 5px;
      padding: 10px;
    }

    &__tags-add {
      width: 50%;
    }
  }

  &__item {
    height: 28px;
    padding: 5px !important;
    background: #f1f4fc !important;

    &__red {

      svg {
        font-size: 1em;
        fill: red;
      }
    }
    &__green {

      svg {
        font-size: 1em;
        fill: #00c500;
      }
    }
  }
}