@import 'src/style/mixin.scss';

.react-calendar-timeline {
  @include bg(0);
  .rct-calendar-header {
    border: unset;
    border-left: 1px solid $primary-grey;
  }
  .rct-header-root {
    background-color: $primary-green;
    border: unset;
    position: sticky;
    top: 0;
    z-index: 81;
  }
  .rct-dateHeader-primary {
    color: $primary-black;
    border-bottom: 1px solid $primary-grey !important;
  }
  .rct-dateHeader {
    border-bottom: 1px solid $primary-grey;
    border-left: 1px solid $primary-grey;
  }
  .rct-outer {
    position: relative;
  }
  .rct-sidebar-row {
    @include flex(center);
    justify-content: space-between;
    white-space: unset !important;
    padding: 5px !important;
    line-height: 16px !important;
  }
}
.calendar {
  &__group {

    &__item div {
      @include font(20);
      color: $primary-black;
      cursor: pointer;
      text-decoration: none;
      transition: 0.3s all;
      &:hover,
      &:active,
      &:focus {
        color: $primary-dark-black;
      }
    }

    &__info {
      @include flex(flex-start, 3, row, center);
      font-size: 15px !important;
    }
  }
  &__item {
    @include flex($flexDirection: column);
    font-size: 24px !important;
    overflow: hidden;
    padding: 3px;
    line-height: 14px !important;

    &.big {
      height: 77px !important;
    }

    &.small {
      height: 35px !important;
    }

    &__container {
      display: flex;
      width: 100%;
      &__bottom-line {
        display: flex;
        width: 100%;

        &.big {
          flex-direction: column;
        }
    
        &.small {
          flex-direction: row;
          gap: 5px;
        }
      }
    }
  }
  &__order-info {
    @include font(14, $primary-black, 300);
    @include flex(flex-start, 5, column, center);
    display: flex;
    max-width: 300px;
    overflow: hidden;
    
    &__container {
      width: 100%;
      @include flex(center, 10, row, space-between);
    }

    &__icon {
      cursor: pointer;
    }
  }
  .calendar-filter {
    @include bg(0, $bg: $secondary-grey);
    padding: 10px;
    margin-bottom: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    align-items: center;
    
    &__oneway {
      display: flex;
      text-align: center;
      gap: 30px;

      @media (min-width: 1025px) {
        border-left: 1px solid #7d879c5e;
        padding-left: 20px;
        margin-left: 20px;
      }
      &__item {
        width: 100px;
        
        p {
          margin-bottom: 0;
          @include font(16, $primary-light-black, 600);
          @media (max-width: 450px) {
            font-size: 12px;
          }
          &::first-letter {
            text-transform: capitalize;
          }
        }

        @media (max-width: 1025px) {
          @include flex(center, 0, row, center);
          white-space: nowrap;
        }
      }
    }
  
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  &__category {
    @include font(16, $primary-dark-black, 600);
    text-transform: uppercase;
    letter-spacing: -1px;
    background-color: $primary-green;
  }
  &__lockIcon {
    fill: #4b566b !important;
    cursor: pointer;
  }
}

.calendar-sidebar {
  text-align: center;
  margin-top: 15px;

  svg {
    fill: #4b566b;
    cursor: pointer;
  }
}