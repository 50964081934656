@import "src/style/mixin.scss";

.no-result {
  @include flex(center, 5, column, center);
  @include font(24, $primary-black, 800);
  margin: 150px auto 0;
  img {
    border-radius: 50%;
    filter: drop-shadow(0 0 10px rgb(255, 255, 255));
  }
  div {
    margin-top: 30px;
    &:last-child {
      margin-top: 0;
      font-size: 18px;
    }
  }
}
