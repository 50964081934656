@import "src/style/mixin.scss";

.login {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 150px 250px;
  @include flex(center, 0, row, center);
  &__wrapper {
    @include bg();
    @include flex(center, 0, row, center);
    width: 350px;
    padding: 35px 20px;
  }
  &__content {
    justify-items: center;
  }
  &__header {
    @include font(28, $primary-dark-black, 600);
    margin: 0 0 15px 0;
  }
  &__form {
    width: 100%;
    text-align: left;
    @include flex(center, 10, column);
  }
  &__text {
    @include font(16, $primary-light-black);
    margin-top: 45px;
  }
  &__result {
    @include font(14, $primary-red, 500);
  }
}

@media (max-width: 1024px) {
  .login {
    padding: 150px 0;
  }
}

.conditions {
  margin-top: 10px;
  
  &__iconsContainer {
    position: relative; 
    width: 0px
  }
  &__circleIcon {
    position: absolute; 
    fill: #c1ff45 !important
  }
  &__checkIcon {
    position: absolute; 
    fill: #4b566b !important;
    top: 5px;
    left: 5px
  }
  &__text {
    @include font(16, $primary-light-black);
    margin-left: 35px;

    a {
      @include font(16, $primary-light-black !important);
      text-decoration: underline !important;
    }
  }
}