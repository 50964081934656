@import "src/style/mixin.scss";

.order-docs {
  @include flex(flex-start, 8, column, center);
  &__item {
    @include font(16);
    @include flex(center);
    & > div {
      display: inline-block;
    }
    svg:hover path {
      stroke: $primary-black;
    }
    &__title {
      @include font(16, $primary-dark-black);
      margin-right: 5px;
    }
    &__data {
      text-indent: 3px;
    }
  }
}
