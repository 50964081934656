@import "src/style/mixin.scss";

.action-wrapper {
  @include flex(center, 8, row, flex-end);
}

.action-box {
  @include flex(center, $justifyContent: center);
  display: inline-flex;
  width: 24px;
  height: 24px;
}
