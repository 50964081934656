@import 'src/style/mixin.scss';

.order-common {
  margin-bottom: 50px;
  @include flex(flex-start, 5, column, center);
  &__item {
    @include font(16);
    & > div {
      display: inline-block;
    }
    &__title {
      @include font(18, $primary-dark-black);
    }
    &__subtitle {
      font-size: 15px;
      margin-bottom: 5px;
    }
    &__data {
      text-indent: 3px;
    }

    & > .action-box {
      cursor: pointer;
      &:hover {
        path {
          fill: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }
}
