@import 'src/style/mixin.scss';

.order-notification {
  @include flex(center, $justifyContent: center);
  transform: translateY(2px);
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    & > svg {
      transition: 0.5s all;
      transform: rotate(-90deg);
      path {
        stroke: $primary-black;
      }
    }
  }

  svg {
    transition: 0.5s all;
  }
  &__list {
    @include flex(flex-start, 5, column, center);
    @include font();
    &__item {
      @include flex(center, 5, row, space-between);
      width: 100%;
      height: 40px;
      text-transform: capitalize;
      padding: 7px 0;
      border-bottom: 1px solid $primary-light-grey;
      &:last-child {
        border-bottom: unset;
      }
      .order-notification__action {
        @include font();
        @include flex(center, 5, row, center);
        &__item {
          @include flex(center, 5, row, center);
          width: 100%;
          padding: 2px;
          &:hover > svg path {
            fill: $primary-dark-black;
          }
        }
      }
    }
  }
}
div[aria-labelledby] {
  & > svg {
    transition: 0.5s all;
    transform: rotate(-90deg);
    path {
      stroke: $primary-black;
    }
  }
}
