@import 'src/style/mixin.scss';

.order-payment {
  &__status {
    @include font(16, $primary-dark-black);
    margin-bottom: 10px;
  }

  &__history {
    padding: 2px;
    max-height: 500px;
    overflow: scroll;

    &__item {
      border-radius: 5px;
      padding: 7px 10px;
      margin-bottom: 7px;
      border-top: 1px solid $primary-light-grey;

      &:first-of-type {
        border-top: unset;
      }

      &:last-of-type {
        margin-bottom: unset;
      }

      &__date {
        @include font();
      }

      &__price {
        @include font(15, $primary-dark-black, 600);
      }

      &__note {
        margin-top: 5px;
        margin-bottom: 5px;
        @include font(14, $primary-light-black);

        &.pending {
          color: $primary-red;
        }

        &__container {
          @include flex(center, 5, row, center);
          margin-right: 5px;
        }

        &__stripe {
          padding: 2px;
          border-radius: 3px;
          background-color: #6058f7;

          a {
            @include font(14, $primary-white !important, 600);
          }
        }

      }

      &__actions {
        @include flex(center, 5, row, center);

        &__receipt {
          height: 20px;
          min-width: auto !important;
          padding: 0 5px !important;
          font-size: 12px !important;
          background-color: $primary-white !important;
        }
      }

      &.green {
        background-color: $opacity-secondary-green;
      }

      &.red {
        background-color: $opacity-primary-red;
      }

      &.grey {
        background-color: #eee;
      }

      &.yellow {
        background-color: #f6eb6c;
      }

      .orange {
        background-color: #ffb67a;
      }

      &.admin {
        background-color: $primary-green;
      }

      &.host {
        background-color: #7ac6fc;
      }

      .flex_CSB {
        @include flex(center, $justifyContent: space-between);
      }
    }
  }

  &__total {
    margin-bottom: 20px;
    padding: 20px 0 20px 10px;
    @include flex(flex-start, 5, column, space-between);
    border-bottom: 1px solid $primary-light-grey;

    &__item {
      width: 100%;
      @include flex(center, 5, row, space-between);

      &:not(:last-child) {
        border-bottom: 1px solid $primary-light-grey;
      }
    }

    &__title {
      @include font(18, $primary-black, 700);
    }

    &__subtitle {
      margin-top: 2px;
      @include font(12, $primary-black, 400);
    }

    &__price {
      font-size: 18px;
      font-weight: 600;
    }

    .partial {
      color: $secondary-green;
    }

    .paid {
      color: $primary-blue;
    }

    .full {
      color: $primary-red;
    }
  }

  &__link {
    padding: 20px 0;

    &__container {
      @include flex(center, $justifyContent: space-between);
    }

    &__icons {
      @include flex(flex-start, 4, row, flex-end);

      &__copy {
        cursor: pointer;

        svg {
          fill: $primary-black;
        }

        &:hover path {
          fill: $primary-dark-black;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__content {
      @include flex(center, $justifyContent: space-between);

      &__note {
        margin-top: 5px;
        overflow: hidden;
        @include font(14, $primary-light-black, 400, 16);
      }
    }

    h4 {
      @include font(14, $primary-black, 700);
    }

    p {
      @include font(12, $primary-black, 400, 16);
    }
  }

  &__extras {
    padding-bottom: 20px;
    border-bottom: 1px solid $primary-light-grey;

    &__tooltip-container {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    &__buttons {
      @include flex(center, 10, row, space-between);

      button {
        width: 100% !important;
        min-height: 45px;

        &.Mui-disabled {
          background-color: rgba(225, 226, 234, 1) !important;
          border: none;
          opacity: 0.5;
        }
      }

      h3 {
        @include font(14, $primary-black, 700);
        margin-bottom: 5px;
      }

      p {
        @include font(12, $primary-black, 600);
      }
    }

    &__title {
      @include flex(center, 10, row, space-between);
      @include font(18, $primary-black, 700);
      margin-bottom: 5px;
    }

    &__subtitle {
      @include flex(flex-start, 10, column, center);
      @include font(14, $primary-black, 300, 16);
      margin-bottom: 15px;
    }

    span {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 700;

      &:hover {
        color: $primary-dark-black,
      }
    }
  }

  &__hold {
    @include flex(flex-start, 10, row, space-between);
    padding: 20px 0 20px 0;
    border-bottom: 1px solid $primary-light-grey;

    &__container {
      margin-top: 3px;
    }

    h4 {
      @include font(16, $primary-black, 400);
      margin-bottom: 5px;
    }

    b {
      @include font(16, $primary-black, 700);
    }

    p {
      @include font(14, $primary-black, 400, 16);
    }
  }

  &__transfer {
    width: 100% !important;
    height: 30px;
    margin-top: 30px !important;
    padding: 6px 10px !important;
    text-wrap: nowrap;

    &.Mui-disabled {
      background-color: rgba(225, 226, 234, 1) !important;
    }
  }

  &__add {
    width: 100%;
    background-color: rgba(225, 226, 233, 1) !important;

    span {
      margin-left: 5px;
    }
  }

  &__reminder {
    @include flex(flex-start, 7, column, center);

    &__container {
      @include flex(center, 10, row, space-between);
    }

    h4 {
      @include font(16, $primary-black, 600);

      span {
        cursor: pointer;
        text-decoration: underline;
        @include font(16, $primary-black, 700);
  
        &:hover {
          color: $primary-dark-black,
        }
      }
    }

    p {
      @include font(14, $primary-black, 600, 16);
    }

    b {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.pay-info {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    line-height: 17px;

    span {
      font-weight: 700;
      color: $primary-green;
    }
  }
}

.black-text {
  color: #4b566b;
}