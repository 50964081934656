@import 'src/style/mixin.scss';

.client-modal {

  @media (max-width: 560px) {
    width: auto !important;
    margin: 50px 10px;
  }

  @media (max-width: 400px) {
    padding: 30px;

    &__form {
      display: flex;
      flex-wrap: wrap;

      & > div {
        width: 100%;
      }
    }
  }
}