@import "src/style/mixin.scss";

.main {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 240px auto;
  gap: 16px;
  .vans-time {
    width: 100%;
    @include flex(flex-start, 16, column);
    grid-row: 1/4;
    grid-column: 2/3;
  }
  .vans {
    @include flex(flex-start, 16, column);
  }
}
.vans-header {
  @include flex(center, $justifyContent: space-between);
  margin-bottom: 16px;
  &__title {
    @include font(20, $primary-black, 600);
  }
}
.header-amount {
  @include font(20, $primary-light-black, 600);
}
