.notification-menu {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 2px;
    padding-top: 10px;
    padding-bottom: 10px;

    &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: #4b566b;
    }
}

.underline {
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
}