@import "src/style/mixin.scss";

.van-card {
  @include flex(flex-start, 10, column);
  @include bg();
  width: 100%;
  padding: 20px;
  gap: 3px;
  &__header {
    width: 100%;
    @include flex(flex-start, $justifyContent: space-between);
    &__mark {
      @include font(20, $primary-dark-black, 600, 24);
      @include flex(center, 5px);
    }
  }
  &__tags {
    @include flex(flex-start, 4, row, center);
    margin-bottom: 5px;
    &__item {
      @include bg-box();
      background-color: $secondary-grey;
    }
  }
  &__info {
    @include flex(flex-start, 8, column, center);
    width: 100%;
    &__important {
      @include flex(center, 5);
    }
    &__price {
      @include bg-box();
    }
    &__extra {
      @include flex(center, 5, row, center);
      &__item {
        @include bg-box();
      }
    }
    &__description {
      @include font(16, $primary-light-black, 400, 21);
      max-height: 44px;
      overflow: hidden;
      text-overflow: ellipsis;
      &_more {
        margin-left: 4px;
        @include font(16, $primary-dark-black);
        text-decoration: underline !important;
      }
    }
    &__capacity {
      @include bg-box();
      &__item {
        @include flex(center, 6, row, center);
        pointer-events: none;
        svg {
          fill: $primary-black;
        }
      }
    }
  }
  &__imgs {
    @include flex(center, $justifyContent: center);
    width: 1000px;
    margin-top: 5px;
    max-height: 150px;
    transition: 0.5s all;
    @media (max-width: 1550px) {
      width: 600px;
    }
    @media (max-width: 1140px) {
      width: 400px;
    }
    &.active {
      width: 1000px;
      @media (max-width: 1440px) {
        width: 600px;
      }
      @media (max-width: 1024px) {
        width: 400px;
      }
    }
  }
}
.vans-gallery {
  @include flex(center, 10, column, center);
  @include bg(5, unset, transparent);
  margin: 150px auto 0;
  padding: 10px;
  width: 850px;
  height: 600px;
  &__swiper1 {
    width: 100%;
    height: 100%;
  }
  &__swiper2 {
    width: 100%;
    height: 20%;
  }
}
