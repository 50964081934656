.search {
  width: 300px;
  position: relative;
  &__icon {
    z-index: 5;
    position: absolute;
    fill: #d0d4da;
    left: 8px;
    top: 12px;
  }
}
