@import "src/style/mixin.scss";

.pagination {
  display: flex;
  margin: 0 0 0 auto;
  &__wrapper {
    @include flex(center, 10, row, center);
    margin: 0 0 0 auto;
    & > div {
      height: 40px;
      .MuiSelect-select {
        padding: 10px;
        min-height: unset;
      }
      @media (max-width: 380px) {
        height: 30px;

        .MuiInput-root {
          height: 30px;
        }
      }

      @media (max-width: 350px) {
        height: 25px;

        .MuiInput-root {
          height: 25px;
        }
      }
    }
  }
  &__info {
    @include font(14, $primary-grey, 600);
  }
  &-arrow,
  &-item,
  &-link {
    @include flex(center, $justifyContent: center);
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  &-item {
    background: $primary-white;
    border: 1px solid $primary-grey;
    border-left: none;
    &:first-of-type {
      border-left: 1px solid $primary-grey;
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }
    &:hover {
      background-color: $primary-light-grey;
    }

    @media (max-width: 420px) {
      width: 30px;
      height: 30px;
    }

    @media (max-width: 350px) {
      width: 25px;
      height: 25px;
    }
  }
  &-link {
    @include font(14, $primary-light-black, 500);
    text-decoration: none;
    &:hover {
      color: $primary-black !important;
      .pagination__icon_left path,
      .pagination__icon_right path {
        fill: $primary-black;
      }
    }
  }
  &__icon {
    &_left,
    &_right {
      fill: $primary-light-black;
    }
    &_left {
      transform: rotate(90deg);
    }
    &_right {
      transform: rotate(-90deg);
    }
  }
}
.active-link {
  color: $primary-light-black !important;
}
