@import 'src/style/mixin.scss';

.order-cart {
  @include flex(stretch, 20, column, center);
  &__wrapper {
    @include flex(flex-start, 10, column);
    border-bottom: 1px solid $primary-light-grey;
  }
  &__wrapper-total {
    &__info {
      @include flex(flex-end, 0, column, center);
      margin-bottom: 20px;
      .extra-card__price {
        @include font(16, $primary-dark-black, 600);
      }
    }
  }
  &__subtitle {
    @include font(14, $primary-light-black, 300);
  }
  &__van {

    &__title {
      @include font(14, $primary-dark-black, 600);
    }
    &__link {
      color: rgb(0, 127, 255) !important;
      text-decoration: underline !important;
      margin-left: 5px;
    }
    &__container {
      position: relative;
      @include flex(center, 10, row, space-between);
      margin-bottom: 10px;
    }
    &__img {
      width: 150px;
      height: 100px;
      border-radius: 100%;
      @include img();
      img {
        border-radius: 5px;
      }
      svg {
        width: 40px;
        height: 40px;
        fill: black;
        }
    }
  }
  &__item {
    width: 100%;
    display: grid;
    grid-template-columns: 42px auto 70px;
    gap: 5px;
    position: relative;
    &__img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      @include img();
      img {
        border-radius: 5px;
      }
      svg {
        width: 40px;
        height: 40px;
        fill: black;
      }
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
    &__info {
      @include flex(flex-start, 4, column, center);
      &__title {
        @include font(16, $primary-dark-black, 600);
      }
      &__description {
        @include font();
      }
    }
    &__price {
      @include flex(center, 1, row, flex-end);
      @include font(16, $primary-dark-black, 600);
      text-align: right;
      cursor: pointer;
      &.old {
        text-decoration: line-through;
        @include font(14, $primary-light-black, 600);
      }
      &.new {
        position: absolute;
        top: 25px;
        @include font(16, $primary-red, 600);
      }
      &__action {
        @include flex(center, $justifyContent: center);
        width: 20px;
        height: 20px;
        border-radius: 3px;
        transform: translateY(13%);
        cursor: pointer;
        svg {
          transform: rotate(-90deg);
          path {
            stroke: $primary-red;
            fill: $primary-red;
          }
        }
        &:hover {
          background-color: $opacity-primary-red;
        }
        &:first-of-type {
          border: 1px solid $primary-red;
        }
        &:last-of-type {
          border: 1px solid $secondary-green;
          svg path {
            stroke: $secondary-green;
            fill: $secondary-green;
          }
          &:hover {
            background-color: $opacity-secondary-green;
          }
        }
      }
      .MuiOutlinedInput-root {
        min-height: 25px;
        width: 60px;
      }
    }
  }
}

.extras-title {
  align-items: flex-end
}

@media screen and (max-width: 335px) {
  .order-cart {
    &__van {
      &__container {
        flex-direction: column;
        gap: 15px;
      }
    }
    &__item {
      &__price {
        &.new {
          top: 102px;
        }
      }
    }
  }
}