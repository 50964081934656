@import 'src/style/mixin.scss';

.order-table {
  .flex_CC {
    gap: 6px;
    & > .action-box {
      cursor: pointer;
      &:hover {
        path {
          fill: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }
  &__filter {
    @include bg(10, $bg: $secondary-grey);
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    .action-block {
      grid-column: 6;
      display: flex;
      justify-content: flex-end;

      &__host-search {
        display: flex;
        align-items: center;
        margin-right: 30px;
      }
    }

    @media (max-width: 991px) {

      .MuiTabs-root {
        display: none;
      }

      .action-block {
        width: 100%;
        justify-content: flex-end;

        &__input-container {
          width: 100%;
          max-width: inherit;
          display: flex;
          gap: 10px;
          justify-content: flex-end;

          .MuiInputBase-root {
            max-width: inherit;
            width: 150px;
          }

          .MuiFormControl-root {
            width: 150px;
          }
        }
        
        button {
          max-height: 40px;
          min-width: 78px;
          padding: 5px 8px;
        }
      }
    }

    @media (max-width: 520px) {

      .action-block {
        gap: 10px;
        align-items: center;
        justify-content: center;

        &__input-container {
          display: block;
          max-width: 160px;

          .MuiInputBase-root {
            max-width: 160px;
          }
        }
      }
    }

    @media (max-width: 355px) {

      .action-block {
        display: grid;
        justify-content: center;
      }
    }
  }
}