@import 'src/style/mixin.scss';

*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	min-height: 100vh;
}

body {
	margin: 0;
	padding: 0;
}

#root {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  font-family: 'Cairo', sans-serif;
  font-size: 14px;
  text-decoration: none;
}
*::-webkit-scrollbar {
  width: 3px;
}
*::-webkit-scrollbar-track {
  background: $primary-white;
}
*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(250, 250, 250, 0.5);
  border: 1px solid $primary-grey;
}
a:-webkit-any-link,
a {
  color: $primary-black;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s all;
  &:hover,
  &:active,
  &:focus {
    color: $primary-dark-black;
  }
}
//it doesn't work with top properies in firefox
a.react-link {
  color: $primary-black;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s all;
  &:hover,
  &:active,
  &:focus {
    color: $primary-dark-black;
  }
}

.App {
  width: 100%;
  min-height: 100vh;
  background: $secondary-grey;
  position: relative;
  main {
    width: calc(100% - 210px);
    transition: 0.5s all;
    &.active {
      width: calc(100% - 60px);

      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }
}
.add-button {
  position: fixed;
  right: 40px;
  bottom: 40px;
  border-radius: 100%;
  padding: 0;
  min-width: 40px;
  width: 40px;
  height: 40px;
  opacity: 0.9;
}
.mini-sign {
  @include bg(6, none, $primary-white);
  @include flex(center, 10, row, center);
  @include font();
  min-height: 36px;
  min-width: 94px;
  padding: 6px 14px;
  border: 1px solid $primary-light-black;
}
.flex {
  &_CS {
    @include flex(center);
  }
  &_CC {
    @include flex(center, $justifyContent: center);
  }
}
.green {
  color: $secondary-green;
}
.light-green {
  color: $primary-green;
}

.modal {
  height: inherit;
  position: relative;
  padding: 40px;
  margin: 50px auto;
  background: $primary-white;
  border-radius: 10px;

  &.modal-1000 {
    width: 1000px;
  }
  &.modal-850 {
    width: 850px;
  }
  &.modal-650 {
    width: 650px;
  }
  &.modal-500 {
    width: 500px;
    margin-top: 200px;
  }
  &.modal-550 {
    width: 550px;
  }
  &.modal-450 {
    width: 450px;
  }
  &.modal-400 {
    width: 400px;
    margin-top: 160px;
  }

  &.modal-tag {
    .modal__fields {
      .modal__field {
        & > div {
          width: 100%;
          &:last-of-type {
            width: 100px;
            min-width: 100px;
          }
        }
      }
    }
  }

  &__body {
    @include flex(center, 5, row, space-between);
    & > div {
      width: 80%;
    }

    @media (max-width: 376px) {
      flex-wrap: wrap;

      & > div, button {
        width: 100%;
      }
    }
  }
  &__icon {
    @include flex(center);
    width: 50px;
    height: 50px;
    fill: $primary-black;
    margin-bottom: 40px;
  }
  &__title {
    @include font(20, $primary-black, 800);
    text-transform: capitalize;
    margin-bottom: 40px;
    text-align: center;

    @media (max-width: 415px) {
      margin-bottom: 30px;
    }
  }
  &__text {
    @include font(17, $primary-black, 500, 22);
    margin-bottom: 40px;
  }
  &__fields {
    @include flex(flex-start, 10, column, center);
  }
  &__field {
    width: 100%;
    position: relative;
    @include flex(center, 5);
    & > div {
      width: 100%;
    }
    &_icon {
      @include flex(center);
      position: absolute;
      top: 3px;
      left: -30px;
      width: 25px !important;
      height: 25px;
      z-index: 100;
      cursor: pointer;
    }
  }
  &__text-icon {
    @include flex(center, 8);
  }
  &__items {
    @include flex(center, 8);
    flex-wrap: wrap;
    &__title {
      text-transform: capitalize;
      margin: 10px 0;
      @include font(16, $primary-dark-black, 600);
      letter-spacing: 1px;
    }
  }
  &__item {
    @include font();
    @include bg(4, none, $primary-white, 1px solid $primary-grey);
    @include flex(center, 5, row, center);
    padding: 8px 16px;
  }
  &:focus-visible {
    outline: none
  }

  &.modal-1000, &.modal-850, &.modal-650, &.modal-500, &.modal-550, &.modal-450, &.modal-400 {

    @media (max-width: 415px) {
    width: 394px;
    }
    @media (max-width: 394px) {
      width: 370px;
    }
    @media (max-width: 376px) {
      width: 355px;
    }
    @media (max-width: 361px) {
      width: auto;
      padding: 30px;
      margin: 50px 10px;
    }
  }
}
@for $i from 1 through 5 {
  .col-#{$i} {
    display: grid;
    grid-template-columns: repeat($i, minmax(0, 1fr));
    @if $i > 2 {
      gap: 5px;
    } @else {
      gap: 10px;
    }
  }
}

.content {
  &__header {
    @include flex($justifyContent: flex-end);
    margin-bottom: 20px;
  }
}
.icon {
  &_stroke {
    @include flex(center);
    stroke: $primary-black;
    cursor: pointer;
    &:hover path {
      stroke: $primary-dark-black;
    }
  }
  &_fill {
    @include flex(center);
    fill: $primary-black;
    cursor: pointer;
    &:hover path {
      fill: $primary-dark-black;
    }
  }
  &_delete-row {
    display: block;
    position: absolute;
    left: -35px;
    top: 0;
    cursor: pointer;
    &.adding {
      left: unset;
      right: -1.5px;
    }
  }
}

.icon-box {
  @include flex(center, $justifyContent: center);
  display: inline-flex;
  width: 30px;
  height: 30px;
  cursor: pointer;
  svg {
    fill: $primary-light-black;
    &:hover {
      fill: $primary-black;
    }
  }
}
.action-form {
  @include flex(center, 10, row, center);
  .mini-sign {
    min-width: auto;
    min-height: auto;
    height: 24px;
    color: $primary-black;
    transition: 0.3s all;
    &:hover {
      cursor: pointer;
      background: $secondary-grey;
      box-shadow: 0 2px 3px -1px rgb(0 0 0 / 20%), 0 4px 4px 0 rgb(0 0 0 / 14%),
        0 1px 4px 0 rgb(0 0 0 / 12%);
    }
  }
}
.select-category {
  @include font(18, $primary-black, 600);
  padding: 5px 10px;
}
