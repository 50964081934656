@import "src/style/mixin.scss";

.order__store {
  @include flex(center, 20, column);
}
.extra-card {
  @include bg();
  display: grid;
  grid-template-columns: 60px auto 100px;
  gap: 20px;
  padding: 15px;
  width: 100%;
  @media (max-width: 361px) {
    @include flex(center, 10, column, center);
  }
  &__logo {
    width: 60px;
    height: 60px;
    @include img();
  }
  &__info {
    @include flex(flex-start, 7, column);
    &__name {
      width: 100%;
      @include font(18, $primary-black, 600);
    }
    &__title {
      @include font();
    }
    &__description {
      @include font(13, $primary-light-black, 400, 19);
      @media (max-width: 415px) {
        display: none;
      }
    }
    @media (max-width: 361px) {
      @include flex(center, 5, column, center);
      text-align: center;
    }
  }
  &__price {
    @include font(18, $primary-dark-black, 600);
  }
  &__action {
    @include flex(flex-end, 10, column, space-between);
    &__button {
      height: 40px;
      width: 100%;
    }
    &__two-buttons {
      width: 100%;
      margin-top: 5px;
      button {
        height: 40px;
        &:first-child {
          margin-bottom: 5px;
        }
      }
    }
    @media (max-width: 361px) {
      width: 100%;
      @include flex(center, 10, row, space-between);

      &__button {
        width: 100px;
      }
    }
  }
}
