@import "src/style/variables.scss";

.icon-tab {
  path,
  circle {
    color: gray;
  }
  .active path,
  .active circle {
    color: $primary-black;
  }
}

.admin-icon {
  font-size: 1.7rem !important;
  margin-left: -1px;
}

.icon-tab-chat {
  fill: rgb(0, 127, 255) !important;
  font-size: 30px !important;
};