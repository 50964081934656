@import 'src/style/mixin.scss';

.report-filter {
  @include bg(0, $bg: $secondary-grey);
  padding: 10px;
  margin-bottom: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.report {
  &__card {
    gap: 15px;
    width: 100%;
    @include bg();
    padding: 20px;
    &__item {
      display: grid;
      align-content: space-between;
      &__value {
        &__red {
          color: red;
        }
        &__green {
          color: green;
        }
      }
      &__one {
        grid-column: 1/1;
      }
      &__seven {
        grid-column: 6/6;
      }
      &__nine {
        grid-column: 8/8;
      }
      &__eight {
        grid-column: 7/7;
        grid-row: 1/1;
      }
      &__ten {
        grid-column: 9/9;
        grid-row: 1/1;
      }
      &__eleven {
        grid-column: 10/10;
        grid-row: 1/1;
      }
      &__twelve {
        grid-column: 11/11;
        grid-row: 1/1;
        max-width: 180px;
      }
      &__title {
        @include font(15, $primary-black, 500);
        // text-transform: capitalize;
        &__empty {
          @include font(20, $primary-black, 500);

          text-align: center;
        }
      }
      &__subtitle {
        @include font(12, $primary-light-black, 400);
        &__empty {
          @include font(17, $primary-light-black, 400);

          text-align: center;
        }
      }
    }
  }
}
.col-10 {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  gap: 20px;
}
