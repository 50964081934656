@import 'src/style/mixin.scss';

.tabs-wrapper {
    flex-grow: 1;
    background-color: "background.paper";
    display: flex;
    height: 224;
    flex-direction: column;

    .search-button {
        color: $primary-black;
        cursor: pointer;
        transition: 0.3s all;
        border: none;
        background-color: transparent;
        margin-bottom: 5px;

        &:hover,
        &:active,
        &:focus {
            color: $primary-dark-black;
        }

        svg {
            vertical-align: middle;
        }
    }

    .exit-button-wrapper {
        position: sticky;
        top: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .exit-button {
            min-width: unset;
            max-width: unset;
            width: 30px;
            max-height: 40px;

            span {
                margin-right: 0
            }
        }
    }
}