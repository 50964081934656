@import 'src/style/mixin.scss';
@import 'src/style/variables.scss';

.search-modal {

  &__cancel {
    margin-top: 20px !important;
    margin-left: auto !important;
  }

  &__circular {
    width: 100%;
    margin: 10px auto;
    text-align: center;
  }

  &__clients {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 10px 0;
    @include flex(center, 5, column, flex-start);
    border: 1px solid $primary-grey;
    border-radius: 3px,
  }

  &__client {
    width: 100%;
    height: 33px;
    @include flex(center, 0, row, space-between);
    padding: 10px;
    margin: 0 3px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.15);
  }
}