@import "src/style/mixin.scss";

.nearby-locations {

    grid-column: 1/3;

    &__title {
        margin-right: 5px
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        border: solid 1px #ccc;
        border-radius: 5px;
        padding: 10px;
    }

    &__item {
        border: solid 2px $primary-light-black;
        border-radius: 5px;
        text-align: center;
        padding: 5px 10px;
        color: $primary-dark-black;
        background: #f1f4fc;
        font-size: 15px;
        line-height: 20px;

        &__location {
            font-weight: 600;
            cursor: pointer;
        }

        &__price {
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
                width: 17px;
            }
        }
    }
}