@import 'src/style/mixin.scss';

.order-drivers {
  width: inherit;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  // @media (max-width: 415px) {
  //   grid-template-columns: repeat(1, 1fr);
  //   gap: 8px;
  // }
  &__item {
    @include flex(flex-start, 10, column, center);
    @include font(16);
    // @media (max-width: 415px) {
    //   @include flex(flex-start, 10, row, flex-start);
    // }
    & > div {
      display: flex;
    }
    &__title {
      @include font(18, $primary-dark-black, 600);
    }
    &__subtitle {
      @include font(16, $primary-dark-black, 600);
      @media (max-width: 520px) {
        display: none;
      }
    }
    &__documents {
      height: 36px;
      width: 278px;
      @include flex(center, 5, row, center);
      padding: 3px 5px;
      @include bg($bg: #f1f4fc);

      &__container {
        width: 241px;
        min-height: 27px;
        @include flex(center, 5, row, center);
        padding: 3px 5px;
        @include bg($br: 3);
      }

      &__license {
        @include flex(center, 0, row, center);
      }

      @media (max-width: 520px) {
        width: auto;

        &__container {
          width: auto;
          gap: 0;
        }
      }
    }
    &__data {
      height: 36px;
      align-items: center;
      text-indent: 3px;
      text-transform: capitalize;
      @include font(16, $primary-dark-black);
      &__action {
        height: 16px;
        cursor: pointer;
        margin-left: auto;
        display: flex ;
        align-items: center;
        & > svg {
          height: 18px;
        }
        // @media (max-width: 767px) {
        //   margin-left: 25px;
        // }
      }
    }
  }
}
