@import 'src/style/mixin.scss';

.order-show {
  gap: 20px !important;
  position: relative;
  & > div {
    width: 100%;
    @include bg();
    padding: 20px;
  }
  &__title {
    @include flex(center, 5);
    font-weight: bold !important;
    @include font(22, $primary-black, 600);
    margin-bottom: 15px;
    position: relative;

    &__action {
      transition: 0.5s all;
      position: absolute;
      right: 0;
      
      &.find {
        right: 28px;

        &:hover {
          border: 0;
          color: #7d879c;
        }

        svg {
          fill: #7d879c;
        }
      }

      &.add {
        right: 50px;
      }
    }

    &__tooltip {
      padding: 5px;

      &__item {
        display: flex;
        align-items: center;
        gap: 20px;
        color: #fff;
        
        svg {
          fill: #c1ff45;
        }
      }
    }
    
    @media (max-width: 335px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__header {
    margin-bottom: 15px;
    @include flex(flex-start, 5, row, space-between);
    @include font(22, $primary-black, 600);
    font-weight: bold !important;

    &__container {
      @include flex(center, 5);
      flex-wrap: wrap;

      &__title {

        &__actions {
          display: none !important;
        }

        h3 {
          @include font(22, $primary-black, 600);
          font-weight: bold !important;
          text-wrap: nowrap;
    
          span {
            @include font(22, $primary-black, 500);
          }
        }
      }

      &__badges {
        @include flex(center, 5);

        &__oneway-badge {
          width: 60px;
          height: 30px;
          @include flex(center, 0, row, center);
          border-radius: 50px;
          color: white;
          background-color: rgb(0, 127, 255);
        }

        &__ready-badge {
          width: auto;
          padding: 0 10px 0 10px;
          color: #4b566b;
          background-color: #39db39;
        }
      }
    }
  }

  &__subtitle {
    @include flex(center, 5, row, center);
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    color: #4b566b;
  }
  &__subtitle-red {
    @include flex(center, 5, row, space-around);
    @include font(18, $primary-red, 400, 20);
    margin: 20px 0;
    text-align: center;
  }
  &__link {
    @include font(22, $secondary-green, 500);
    text-shadow: 1px 1px 1px $primary-light-grey;
    display: inline-block;
    cursor: pointer;
  }
  &__common {
    grid-column: 1/3;
    .order-show__link {
      color: $primary-black;
    }
  }
  &__notification {
    grid-column: 4/4;
    grid-row: 2/5;
    &__item {
      border-top: 1px solid rgba(204, 204, 204, 0.5);

      &.dedicated {
        padding: 5px;
        background-color: rgba(1, 195, 140, 0.0941176471);
      }
    }
  }
  &__payment {
    position: relative;
    grid-column: 3/4;
    grid-row: 1/3;
    @include flex(center, 5, column, space-between);
    .order-show__title__action.open {
      transform: rotate(45deg);
    }
    & > div {
      width: 100%;
    }
  }
  &__cart {
    grid-column: 1/2;
    grid-row: 2/4;
  }
  &__new-pay {
    width: 300px;
    margin: 300px 46% 0;
    @include flex(stretch, 10, column, center);
    @include bg(5, unset, $primary-white);
    padding: 20px;
    @media (max-width: 415px) {
      margin: 50px auto;
    }
    &__title {
      margin-bottom: 5px;
      @include font(20, $primary-black, 600);
    }
    &__alert {
      margin-left: auto;
      margin-bottom: 10px;
      @include font(14, #d24057, 700);
    }
    &__box {
      width: 100%;
      @include flex();
    }
    &__stripe {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;

      p {
        font-size: 15px;
        margin-bottom: 0;
        margin-left: -8px;
      }
    }
  }
  &_drivers {
    grid-column: 1/3;
    grid-row: 4/4;
  }
  &_note {
    grid-column: 3/3;
    grid-row: 3/5;
  }
  &_actions {
    grid-column: 4/4;
    grid-row: 1/2;
  }
}
@media (max-width: 1024px) {
  .order-show {
    &__notification {
      grid-column: unset;
      grid-row: unset;
    }
    &.col-4 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px !important;
    }
  }
}
@media (max-width: 767px) {
  .order-show {

    &.col-4 {
      display: flex;
      flex-direction: column;
    }

    &__common {
      order: 0
    }

    &__customer {
      order: 1
    }

    &__cart {
      order: 2
    }
    
    &_note {
      order: 3
    }

    &__payment {
      order: 4
    }

    &__docs {
      order: 5
    }

    &_drivers {
      order: 6
    }

    &_actions {
      order: 7
    }

    &__notification {
      order: 8
    }
  }
}

@media (max-width: 400px) {
  .order-show {

    &__header {

      &__container {

        &__title {
          width: 100%;
          @include flex(flex-start, 5, row, space-between);
          
          &__actions {
            display: block !important;
          }
        }
      }

      &__actions {
        display: none !important;
      }
    }
  }
}