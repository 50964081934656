@import 'src/style/mixin.scss';

.tag-module {
  grid-column: 1/3;

  &__title {
    @include flex(center, 0, row, space-between);
  }

  &__container {
    @include flex(flex-start, 10, row, space-between);
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 10px;

    &__expand-icon {
      display: flex;
      margin-top: 5px;
    }
  }

  &__items  {
    display: flex;
    gap: 10px;

    &__hide {
      overflow: hidden;
    }

    &__show {
      overflow: auto;
      flex-wrap: wrap;
    }
    
    div {
      white-space: nowrap;
    }
  }
}

.rotate {
  animation-duration: 1s;
  animation-name: rotate;
  animation-iteration-count: 1;
  transform: rotate(180deg);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}