
.imgs-order {

    width: 700px !important;
    gap: 20px;
    text-align: center;

    &__img-container {

        height: 150px;
        max-width: 200px;
        border: solid 2px #7d879c;
        border-radius: 5px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}