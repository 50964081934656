@import "src/style/variables.scss";

@mixin bg($br: 7, $bsh: $primary-shadow, $bg: $primary-white, $border: none) {
  border-radius: $br + px;
  background: $bg;
  box-shadow: $bsh;
  border: $border;
  @if $br == 0 {
    border-radius: 0;
  } @else {
    border-radius: $br + px;
  }
}

@mixin flex(
  $alignItems: flex-start,
  $gap: 0,
  $flexDirection: row,
  $justifyContent: flex-start
) {
  display: flex;
  align-items: $alignItems;
  flex-direction: $flexDirection;
  justify-content: $justifyContent;
  @if $gap == 0 {
    gap: 0;
  } @else {
    gap: $gap + px;
  }
}

@mixin img {
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@mixin font(
  $fontSize: 14,
  $fontColor: $primary-black,
  $fontWeight: 400,
  $fontHeight: $fontSize
) {
  font-size: $fontSize + px;
  color: $fontColor;
  font-weight: $fontWeight;
  line-height: $fontHeight + px;
  @media (max-width: 991px) {
    font-size: ($fontSize * 0.9) + px;
    line-height: ($fontSize * 0.9) + px;
  }
}

@mixin hover(
  $bg: none,
  $color: $primary-black,
  $border: none,
  $boxShadow: none,
  $fill: $color
) {
  &:hover {
    background: $bg;
    color: $color;
    border: $border;
    box-shadow: $boxShadow;
    fill: $fill;
    cursor: pointer;
  }
}
@mixin bg-off($bsh: none, $bg: none, $border: none) {
  background: $bg;
  box-shadow: $bsh;
  border: $border;
}

@mixin bg-box() {
  @include flex(center, $justifyContent: center);
  @include font();
  align-items: center;
  padding: 6px 12px;
  height: 28px;
  border: 1px solid $primary-grey;
  border-radius: 5px;
}
