@import 'src/style/mixin.scss';

.status-badge {
  @include flex(center, $justifyContent: center);
  width: 80px;
  height: 30px;
  border-radius: 50px;
  &.grey {
    background-color: $primary-light-grey;
    color: $primary-black;
    &__document {
      border: 1px solid $primary-light-grey;
      color: $primary-light-grey;
    }
  }
  &.red {
    background-color: rgba(255, 0, 0, 0.1);
    color: $primary-red;
    &__document {
      border: 1px solid $primary-red;
      color: $primary-red;
    }
  }
  &.blue {
    background-color: rgba(0, 0, 255, 0.1);
    color: $primary-blue;
    &__document {
      border: 1px solid $primary-blue;
      color: $primary-blue;
    }
  }
  &.green {
    background-color: $primary-green;
    color: $primary-black;
    &__document {
      border: 1px solid $primary-green;
      color: $primary-green;
    }
  }
  &.orange {
    background-color: $primary-orange;
    color: $primary-dark-black;
    &__document {
      border: 1px solid $primary-orange;
      color: $primary-orange;
    }
  }
  &.babyblue {
    background-color: $primary-babyblue;
    color: $primary-dark-black;
    &__document {
      border: 1px solid $primary-babyblue;
      color: $primary-babyblue;
    }
  }
  &.pink {
    background-color: $pale-pink;
    color: $primary-dark-black;
    &__document {
      border: 1px solid $pale-pink;
      color: $pale-pink;
    }
  }
  &.dark-green {
    font-weight: 600;
    color: $primary-black;
    background-color: #39db39;
  }
}

.small-badge-container {
  margin-top: 10px;

  &__item {
    width: 30px;
    height: 10px;
    border-radius: 10px;

    &__grey {
      background-color: #aeb8cc;
      &__document {
        background-color: #aeb8cc;
      }
    }
    &__red {
      background-color: rgba(255, 0, 0, 0.1);
      &__document {
        border: 3px solid $primary-red;
      }
    }
    &__blue {
      background-color: rgba(0, 0, 255, 0.1);
      &__document {
        border: 3px solid $primary-blue;
      }
    }
    &__green {
      background-color: $primary-green;
      &__document {
        border: 3px solid $primary-green;
      }
    }
    &__orange {
      background-color: $primary-orange;
      &__document {
        border: 3px solid $primary-orange;
      }
    }
    &__babyblue {
      background-color: $primary-babyblue;
      &__document {
        border: 3px solid $primary-babyblue;
      }
    }
    &__pink {
      background-color: $pale-pink;
      &__document {
        border: 3px solid $pale-pink;
      }
    }
  }
}

.edit-status-badge {
  width: auto;
  padding: 0 10px 0 10px;
  &__icon {
    margin-top: 7px;
    margin-left: 5px;
    cursor: pointer;
    svg {
      height: 14px;
    }
  } 
}
