@import "src/style/mixin.scss";

.logo {
  @include font();
  width: 100%;
  min-height: 60px;
  pointer-events: none;
  &.abbr {
    @include font(28, $primary-black, 700);
    filter: drop-shadow(1px -1px 1px $primary-dark-black);
  }
  &__text,
  b {
    font-size: 11px;
    letter-spacing: 0;
  }
  &:active {
    transform: translateY(2px);
  }
}
