@import "src/style/mixin.scss";

.filter__aside {
  width: 100%;
  @include bg();
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  padding: 20px;
  grid-column: 1/2;
  .filter-type {
    width: 100%;
  }
  .filter-price {
    &__wrapper {
      @include flex(center, 8, row, center);
      & > div {
        width: 100%;
        height: 40px;
      }
    }
  }

  .filter-features {
  }
  .filter-color {
    &__wrapper {
      @include flex(center, 8);
    }
    &__item {
      border-radius: 100%;
      width: 26px;
      height: 26px;
      @include hover(none, none, none, 0 0 3px grey);
    }
  }
  .filter-hostRating {
    &__box {
      @include flex(center, 2);
    }
  }
  .filter-extra {
    @include flex(flex-start, 0, column, center);
  }
}
