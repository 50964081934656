@import 'src/style/mixin.scss';

.charge-modal {
  margin-top: 200px;
  
  &__text {
    white-space: pre-line;
  }

  &__action {
    width: 100%;
    margin-top: 30px;

    button {
      width: 100px;
      margin-left: auto;
    }
  }
}