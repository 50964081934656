.chat {
    width: 800px;
    margin: 20px auto;

    &__titles {
        margin-bottom: -20px;

        h1 {
            font-size: 22px;
            font-weight: 600;
            line-height: 22px;
            color: #091b3d;
        }

        h3 {
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            color: #4b566b;
        }
    }

    &__description {
        margin-top: -20px;

        p, a, b {
            font-size: 18px;
        }

        p {
            font-weight: 500;
            color: #4b566b;
        }

        a {

            margin-left: 6px;
            color: #091b3d !important;
            text-decoration: underline !important;
        }
    }
}