@import "src/style/mixin.scss";

.order-contact {
  gap: 15px;
  &__item {
    margin-bottom: 15px;
    &__title {
      @include font(15, $primary-black, 500);
      text-transform: capitalize;
    }
  }
}
