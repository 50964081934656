@import 'src/style/mixin.scss';

.filter {
  &__title {
    @include font(16, $primary-light-black, 600);
    margin-bottom: 3px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
.filter-line {
  margin: 17px 0;
  padding: 0 18px;
  height: 1px;
  background-color: $primary-light-grey;
}
.filter-actions {
  @include flex(center, 8, row, flex-end);
  margin-top: 20px;
  button {
    height: 30px;
    width: 100%;
    padding: 6px 16px !important;
  }
}

.data-picker {
  @include flex(flex-start, 0, column, center);
  &__wrapper {
    @include flex(center, 0);
  }
  &__calender {
    position: relative;
    width: 130px;
  }
  &__clock {
    width: 95px;
  }
}
.filter {
  &__time {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    
    @media (max-width: 415px) {
      display: flex;
      flex-wrap: wrap;

      div {
        width: 100%;
      }
    }

  }
  &__location {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    @media (max-width: 415px) {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 10px;

      & > div {
        width: 100%;
      }
    }

  }
}
.text {
  @include font();
}