@import "src/style/mixin.scss";

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: $primary-white;
  // width: auto !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 3px;
  padding: 10px 0;
  filter: drop-shadow(0 0 1px $primary-light-black);
  cursor: pointer;
}
.mySwiper {
  width: 100%;
  height: 100%;
  padding: 0 2px;
}
