@import "src/style/mixin.scss";

.doc-modal {
  position: relative;
  width: 600px;
  margin: 50px auto;
  padding: 30px;
  background: #ffffff;
  border-radius: 10px;

  &__button {
    margin-top: 30px;

    button {
      margin-left: auto;
    }
  }

  img {
    max-width: 540px;
  }
}

@media (max-width: 630px) {

  .doc-modal {
    width: auto;
    margin: 50px 10px;
    padding: 10px;

    &__button {
      margin-top: 10px;
    }

    img {
      object-fit: contain; 
      width: 100%; 
      height: 100%;
    }
  }
}