@import "src/style/mixin.scss";

.clear-filters {
  @include flex(center);
  @include font();
  @include bg(5, none, $primary-white, 1px solid #6d727a);
  width: 100px !important;
  height: 40px;

  &:hover,
  &:active {
    @include bg(5, 0 0 2px 0 #03aab4cc, $primary-white, 1px solid #049fcb);
    cursor: pointer;
    color: #049fcb;
  }
}

@media (max-width: 576px) {
  .clear-filters {
    height: 30px;
    font-size: 13px;
  }
}
