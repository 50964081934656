@import "src/style/mixin.scss";

.logo {
  width: 100%;
  margin: 20px 0;
  text-align: center;
  svg {
    filter: drop-shadow(1px -1px 1px $primary-black);
  }
  &__text,
  b {
    text-decoration: none !important;
    font-size: 13px;
    letter-spacing: 1px;
  }
}
