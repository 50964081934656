$primary-green: #c1ff45;
$primary-red: #d24057;
$primary-violet: #c04fff;
$primary-pink: #ff45ae;
$secondary-pink: #ff99d3;
$primary-yellow: #ffff45;
$primary-blue: #151c97;
$primary-black: #4b566b;
$primary-dark-black: #091b3d;
$primary-light-black: #7d879c;
$primary-white: #ffffff;
$primary-grey: #ccc;
$primary-light-grey: rgba(204, 204, 204, 0.15);
$primary-orange: #f08e26;
$primary-babyblue: #89CFF0;
$pale-pink: #edd4c2;
$secondary-grey: #fafbfe;
$secondary-green: #01c38d;
$opacity-primary-red: #d2405621;
$opacity-secondary-green: #01c38c18;

$primary-shadow: (
  0 0 0 0.5px rgba(50, 50, 93, 0.1),
  0 2px 5px 0 rgba(50, 50, 93, 0.1),
  0 1px 1.5px 0 rgba(0, 0, 0, 0.07)
);
