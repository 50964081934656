@import "src/style/mixin.scss";

.table-wrapper {
  width: 100%;
  .react-table {
    width: inherit;
    border-spacing: 0 16px;

    .rt-thead {
      background: transparent;
      .rt-tr {
        .rt-th {
          @include font(16, $primary-light-black, 500, 19);
          text-align: left;
          padding: 0 20px;
          position: relative;
          &:first-of-type::before {
            width: 0;
          }
          &:last-of-type {
            width: 90px;
          }
        }
      }

      @media (max-width: 991px) {
        display: none;
      }
    }
    .rt-tbody {
      width: 100%;
      .rt-tr {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
        border-radius: 11px;
        height: 50px;

        .rt-td {
          background: $primary-white;
          @include font(14, $primary-dark-black, 500);
          padding: 10px 10px;
          &:first-of-type {
            border-radius: 11px 0 0 11px;
          }
          &:last-of-type {
            border-radius: 0 11px 11px 0;
          }
        }
      }
    }
  }
}

.text-wrap {
  text-wrap: balance
}

.tooltip-table {

  &__help {
    padding: 5px;
    @include font(14, $primary-white, 300);
  }

  &__header {
    cursor: pointer;
  
    &:hover {
      color: rgb(0, 127, 255);
      font-weight: 600;
    }
  }
}