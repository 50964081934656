@import 'src/style/mixin.scss';

.order-form {
  @include flex(flex-start, 20, column, center);

  & > div {
    width: 100%;
  }

  &__checkbox {
    @include flex(center, 5, row, flex-start);
    margin-top: 5px;

    span {
      padding: 0;
      @include font(14, $primary-light-black, 600);
    }
  }
}