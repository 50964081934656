@import 'src/style/mixin.scss';

.report-table {
  .flex_CC {
    gap: 6px;
    & > .action-box {
      cursor: pointer;
      &:hover {
        path {
          fill: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }
  &__filter {
    @include bg(10, $bg: $secondary-grey);
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin-right: 10px;
      }
    }
    &__buttons {
      grid-column: 6;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }

  &__container {
    @include flex(center, 5, column);
  }

  .rt-th, .rt-td {
    text-align: center !important;
  }
}
